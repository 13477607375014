import Icon from 'components/Icon';
import { MouseEvent, ReactNode } from 'react';
import { Col, Collapse, Row } from 'reactstrap';
import { v1 as uuid1 } from 'uuid';

interface Props {
  title: string | ReactNode;
  subText?: string | ReactNode;
  editState?: boolean;
  displayEdit?: boolean;
  children?: any;
  isOpen: boolean;
  onCollapseChange: (state: boolean) => void;
  onEditChange?: (state: boolean) => void;
}

const InLineCollapseRow = ({
  title,
  subText,
  onEditChange,
  editState,
  displayEdit = false,
  children,
  onCollapseChange,
  isOpen,
}: Props) => {
  const changeEditState = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onCollapseChange(true);
    onEditChange && onEditChange(!editState);
  };

  return (
    <section key={uuid1()}>
      <Row>
        <Col xs="12">
          <h5 onClick={() => onCollapseChange(!isOpen)} style={{ display: 'flex' }}>
            <Icon name={isOpen ? 'angle-down' : 'angle-right'} style={{ cursor: 'pointer' }} />
            {title}
            {displayEdit && (
              <div onClick={(e) => changeEditState(e)} style={{ marginLeft: '15px', cursor: 'pointer' }}>
                <Icon name="edit" size="sm" />
              </div>
            )}
            {subText && (
              <span
                style={{
                  fontSize: '12px',
                  color: 'gray',
                  marginLeft: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {subText}
              </span>
            )}
          </h5>
        </Col>
      </Row>
      <Collapse isOpen={isOpen}>{children}</Collapse>
    </section>
  );
};

export default InLineCollapseRow;
